import { ENV } from '../utils/constants';

let config = {};

if (process.env.REACT_APP_ENV === ENV.PRODUCTION.KEY) {
  config = require('./config.prod'); // eslint-disable-line global-require
} else if (process.env.REACT_APP_ENV === ENV.DEVELOPMENT.KEY) {
  config = require('./config.dev'); // eslint-disable-line global-require
} else {
  config = require('./config.local'); // eslint-disable-line global-require
}

export default config = {
  debugMode: config.config.debugMode,
  api_key: config.config.api_key,
  serverURL: config.config.serverURL,
  imgUrl: 'https://parth-academic-images.s3.ap-south-1.amazonaws.com',
};
